import React, { useState, useEffect } from "react";
import ResultCard from "./shared/ResultCard";
import battery from "../assets/images/battery.svg";
import system from "../assets/images/system.svg";
import roof from "../assets/images/roof.svg";
import space from "../assets/images/space.svg";
import inverter from "../assets/images/inverter.svg";
import amps from "../assets/images/amps.svg";
import download from "../assets/images/download.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import share from "../assets/images/share.svg";
import X from "../assets/images/X.svg";
import html2canvas from "html2canvas";

function Results() {
  const location = useLocation();
  const navigate = useNavigate();
  const [mohafazaName, setMohafazaName] = useState("");
  const [casaName, setCasaName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [rooms, setRooms] = useState("");
  const [people, setPeople] = useState("");
  const [runningCurrent, setRunningCurrent] = useState("");
  const [value, setValue] = useState(runningCurrent);
  const [noRegion, setNoRegion] = useState(false);
  const [mohafazaCasa, setMohafazaCasa] = useState(false);
  const [modalDefaultOpen, setModalDefaultOpen] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [newValue, setNewValue] = useState(((value - 0) / (300 - 0)) * 100);
  const [data, setData] = useState("");
  const [batteryBankSize, setBatteryBankSize] = useState("");
  const [maxAmp, setMaxAmp] = useState("");
  const [minInverterSize, setMinInverterSize] = useState("");
  const [minLandRequired, setMinLandRequired] = useState("");
  const [pvSize, setPvSize] = useState("");
  const [spaceVolumeNeeded, setSpaceVolumeNeeded] = useState("");
  const [maxAmpere, setMaxAmpere] = useState("");
  const [linkID, setLinkID] = useState("");
  const [dod, setDod] = useState("");
  const [duration, setDuration] = useState("");
  const [hideButtons, setHideButtons] = useState(false);
  const [durationValue, setDurationValue] = useState("");
  const [tips, setTips] = useState([]);
  const [peopleText, setPeopleText] = useState("");
  const [roomsText, setRoomsText] = useState("");
  const [simpleApp, setSimpleApp] = useState("");

  useEffect(() => {
    setValue(runningCurrent);
  }, [runningCurrent]);
  useEffect(() => {
    setMaxAmpere(maxAmp);
  }, [maxAmp]);

  useEffect(() => {
    setSimpleApp(location.state.newSimpleApp);
  }, [location.state]);

  useEffect(() => {
    if (data) {
      if (data[0].battery_bank_size) {
        setBatteryBankSize(data[0].battery_bank_size);
      }
      setMaxAmp(data[0].max_amp);
      setMinInverterSize(data[0].min_inverter_size);
      setMinLandRequired(data[0].min_land_required);
      setPvSize(data[0].pv_size);
      if (data[0].space_volume_needed) {
        setSpaceVolumeNeeded(data[0].space_volume_needed);
      }
      setRunningCurrent(data[0].running_current);
      setDod(data[0].dod);
      setTips(data[0].tips);
      setLinkID(data[0].results_link);
    }
  }, [data]);

  const results = [
    {
      number: <p className="mb-0 card-number">{batteryBankSize}</p>,
      unit: "kWh",
      image: <img src={battery} alt="" className="pt-3 result-card-image" />,
      title: "Lithium Battery Bank",
      text:
        "A type of rechargeable battery which uses the reversible reduction of lithium ions to store energy",
    },
    {
      number: <p className="mb-0 card-number">{pvSize}</p>,
      unit: "kWp",
      image: <img src={system} alt="" className="pt-3 result-card-image" />,
      title: "PV system size",
      text:
        "Maximum Power that solar panels can generate according to the number of panels installed",
    },
    {
      number: <p className="mb-0 card-number">{minLandRequired}</p>,
      unit: "m2",
      image: <img src={roof} alt="" className="pt-3 result-card-image" />,
      title: "Approximate roof or land area required",
      text:
        "Area that will be covered by all solar panels including inclination to avoid shading",
    },
    {
      number: <p className="mb-0 card-number">{spaceVolumeNeeded}</p>,
      unit: "m3",
      image: <img src={space} alt="" className="pt-3 result-card-image" />,
      title: "Approximate space volume needed for batteries",
      text:
        "The space needed to install batteries shall be well ventilated and away from water (rain) and sun",
    },
    {
      number: <p className="mb-0 card-number">{minInverterSize}</p>,
      unit: "kW",
      image: <img src={inverter} alt="" className="pt-3 result-card-image" />,
      title: "Minimum Inverter Size",
      text: "The maximum power that can be consumed before tripping",
    },
    {
      number: <p className="mb-0 card-number">{maxAmp}</p>,
      unit: "A",
      image: <img src={amps} alt="" className="pt-3 result-card-image" />,
      title: "Maximum Amps delivered from Inverter",
      text: "The maximum current that can be withdrawn before tripping",
    },
  ];
  var min = 0;
  var max = maxAmpere;
  function calculate(e) {
    setValue(e.target.value);
  }
  useEffect(() => {
    setNewValue(((value - min) / (max - min)) * 100);
  }, [value]);

  const copy = async () => {
    await navigator.clipboard.writeText(
      `I just used this solar calculator from UNDP to get an energy management estimate for my home, try it here: https://undpcalculator.whitebeard.net/#/results/${linkID} `
    );
    // alert("Text copied");
  };

  useEffect(() => {
    if (location.state) {
      setRooms(location.state.rooms);
      setPeople(location.state.people);
      setMohafazaName(location.state.mohafazaName);
      setCasaName(location.state.casaName);
      setRegionName(location.state.regionName);
      setData(location.state.data);
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state && location.state.regionName === "All") {
      setNoRegion(true);
    }
  }, [location.state]);
  useEffect(() => {
    if (
      location.state &&
      location.state.mohafazaName === location.state.casaName
    ) {
      setMohafazaCasa(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (regionName === "" && !location.state) {
      navigate("/");
    }
  }, [regionName]);

  useEffect(() => {
    setDurationValue(value);
  }, [value]);

  useEffect(() => {
    setDuration((batteryBankSize * 1000 * dod) / (220 * durationValue));
  }, [value, dod, batteryBankSize, durationValue]);

  function screenshot() {
    setHideButtons(true);
    const input = document.getElementById("results-page");
    setTimeout(() => {
      html2canvas(input).then((canvas) => {
        const imgWidth = 208;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let a = document.createElement("a");
        a.download = "undp-calculator-results.jpg";
        a.href = canvas.toDataURL("image/jpg");
        a.click("JPG", 0, 0, imgWidth, imgHeight);
        setHideButtons(false);
      });
    }, 1000);
  }

  useEffect(() => {
    if (people && rooms) {
      setPeopleText(` and ${people} people`);
    } else if (people && !rooms) {
      setPeopleText(` with ${people} people`);
    }
  }, [people, rooms]);

  useEffect(() => {
    if (rooms) {
      setRoomsText(` with ${rooms} rooms`);
    }
  }, [rooms]);

  return (
    <div>
      <div
        className="results-page pb-3 mx-lg-auto pt-4 position-relative"
        id="results-page"
      >
        {modalOpen ? (
          <div className="averagesModal">
            <h4>The averages in your area are:</h4>
            <button
              type="button"
              className="btn btn-submit"
              data-bs-dismiss="modal"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              <img src={X} alt="" />
            </button>
            <div className="averages">
              <div className="averages_first_row">
                <h4>Appliance</h4>

                <p>Air Conditioner split unit - 9000BTU</p>
                <p>Air Conditioner split unit - 12000BTU</p>
                <p>Electric Water Heater</p>
                <p>Well Pump - 1/3hp</p>
                <p>TV</p>
                <p>Game Console</p>
                <p>Home Internet Router</p>
                <p>Home Phone</p>
                <p>Laptop Computer</p>
                <p>Phone Charger</p>
                <p>Refrigerator/Freezer</p>
                <p>Microwave</p>
                <p>Electric Kettle</p>
                <p>Water Dispenser</p>
                <p>Light Bulb</p>
                <p>Washing Machine</p>
              </div>
              <div className="data">
                <h4 className="justify-content-center">Max Wattage</h4>
                <p>1500</p>
                <p>2300</p>
                <p>1500</p>
                <p>750</p>
                <p>150</p>
                <p>200</p>
                <p>15</p>
                <p>5</p>
                <p>100</p>
                <p>7</p>
                <p>300</p>
                <p>2500</p>
                <p>1800</p>
                <p>200</p>
                <p>40</p>
                <p>800</p>
              </div>
              <div className="data">
                <h4 className="justify-content-center">
                  Avg Daily
                  <br /> Use (hrs)
                </h4>
                <p>5</p>
                <p>5</p>
                <p>1</p>
                <p>0.5</p>
                <p>6</p>
                <p>2</p>
                <p>24</p>
                <p>24</p>
                <p>6</p>
                <p>24</p>
                <p>24</p>
                <p>0.1</p>
                <p>0.1</p>
                <p>24</p>
                <p>12</p>
                <p>1</p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {simpleApp ? (
          <p className="averageText mx-4">
            The results below are based on your zone average with continuous
            load.&nbsp;
            <span
              onClick={() => {
                setModalOpen(true);
              }}
            >
              Learn more
            </span>
          </p>
        ) : (
          ""
        )}

        <h1
          className={`text-light start-text mx-2 ${mohafazaCasa === true &&
            "d-none"}`}
        >
          {noRegion === false
            ? `Your house in ${regionName}, ${mohafazaName}, ${casaName}${roomsText}${peopleText}.`
            : `Your house in ${mohafazaName}, ${casaName}${roomsText}${peopleText}.`}
        </h1>
        <h1
          className={`text-light start-text mx-2 ${mohafazaCasa === false &&
            "d-none"}`}
        >
          {mohafazaCasa === true
            ? `Your house in ${casaName}${roomsText}${peopleText}.`
            : ""}
        </h1>

        <h3 className="pt-4 text-light">
          Based on your inputs, you will need:
        </h3>
        <div className="d-flex row justify-content-evenly m-auto mx-4">
          {results.map(({ image, number, unit, title, text }, index) => {
            return (
              <ResultCard
                image={image}
                title={title}
                unit={unit}
                number={number}
                key={index}
                text={text}
              />
            );
          })}
        </div>

        {runningCurrent ? (
          <div className="text-center mx-4 mt-4">
            <p className="result-page-estimate-text mb-2">
              Below estimates are set to 1 day of battery Power autonomy*
            </p>
            <div className="result-page-supply">
              <p className="pb-2 mb-0">
                Your approximate system can thereby supply:
              </p>
              <input
                type="range"
                id="supply"
                min="0"
                max={maxAmpere}
                value={value}
                className="pt-2"
                onChange={(e) => {
                  calculate(e);
                  setDurationValue(e.target.value);
                }}
                style={{
                  background:
                    "linear-gradient(to right, #4B4BFD 0%, #4B4BFD " +
                    newValue +
                    "%, rgba(75, 75, 253, 0.2) " +
                    newValue +
                    "%, rgba(75, 75, 253, 0.2) 100%)",
                }}
              />
              <div
                htmlFor="supply"
                className="pt-2 text-start result-page-hours ms-3"
              >
                {value}A
              </div>

              <div className="d-flex justify-content-between align-items-center px-3">
                <p className="mb-0">over a period of:</p>
                <div className="result-page-hours">
                  {!duration || duration === Infinity
                    ? ""
                    : `${Math.round(duration)} Hrs`}
                </div>
              </div>
            </div>
            <p className="power-autonomy-text pt-2 mx-1">
              *Power autonomy = the time during which the load can be met with
              the battery alone, without any solar inputs, starting of course
              from a “full charged” battery state.
            </p>
          </div>
        ) : (
          ""
        )}

        {modalDefaultOpen === true && hideButtons === false ? (
          <div>
            {!simpleApp ? (
              <Link to="/tips" state={{ tips: tips, pvSize: pvSize }}>
                <button className="tips m-auto mt-3 py-3 text-center">
                  Check all your tips here.
                </button>
              </Link>
            ) : (
              ""
            )}
            <button
              type="button"
              className="ms-lg-2 ms-md-2 ms-sm-2 download-btn mb-2 mt-2 text-center"
              onClick={screenshot}
            >
              Download my results <img src={download} alt="" className="ps-2" />
            </button>
            <button
              type="button"
              className="share-btn "
              onClick={() => {
                setModalDefaultOpen(false);
              }}
            >
              <img src={share} alt="" width="10" className="share-svg" />
              <p className="power-autonomy-text pt-2">Share my results</p>
            </button>
          </div>
        ) : (
          <div className={`d-block ${modalDefaultOpen === true && "d-none"}`}>
            <div className="modal-dialog mx-4">
              <div className="modal-body mb-3 p-0 mx-4">
                <div className="pt-3 pe-3 float-end mb-4">
                  <button
                    type="button"
                    className="btn-close shadow-none"
                    onClick={() => {
                      setModalDefaultOpen(true);
                    }}
                  ></button>
                </div>
                <h3 className="lh-sm text-start location-text pt-4 mb-1">
                  I just used this solar calculator from UNDP to get an energy
                  management estimate for my home, try it here:
                  <br />
                  <br />
                  <p className="link">
                    https://undpcalculator.whitebeard.net/#/results/{linkID}
                  </p>
                </h3>
              </div>
              <div className="modal-footer my-4 border-0 text-start mx-4">
                <button onClick={copy}>Copy message</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Results;
