import React, { useState, useEffect } from "react";
import ResultCardAr from "./shared/ResultCardAr";
import battery from "../assets/images/battery.svg";
import system from "../assets/images/system.svg";
import roof from "../assets/images/roof.svg";
import space from "../assets/images/space.svg";
import inverter from "../assets/images/inverter.svg";
import amps from "../assets/images/amps.svg";
import { useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import axios from "axios";

function ResultIdAr() {
  const { id } = useParams();
  const [mohafazaName, setMohafazaName] = useState("");
  const [casaName, setCasaName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [rooms, setRooms] = useState("");
  const [people, setPeople] = useState("");
  const [runningCurrent, setRunningCurrent] = useState("");
  const [value, setValue] = useState(runningCurrent);
  const [noRegion, setNoRegion] = useState(false);
  const [mohafazaCasa, setMohafazaCasa] = useState(false);
  const [newValue, setNewValue] = useState(((value - 0) / (300 - 0)) * 100);
  const [data, setData] = useState("");
  const [batteryBankSize, setBatteryBankSize] = useState("");
  const [maxAmp, setMaxAmp] = useState("");
  const [minInverterSize, setMinInverterSize] = useState("");
  const [minLandRequired, setMinLandRequired] = useState("");
  const [pvSize, setPvSize] = useState("");
  const [spaceVolumeNeeded, setSpaceVolumeNeeded] = useState("");
  const [maxAmpere, setMaxAmpere] = useState("");
  const [linkID, setLinkID] = useState("");
  const [dod, setDod] = useState("");
  const [hideButtons, setHideButtons] = useState(false);
  const [peopleText, setPeopleText] = useState("");
  const [roomsText, setRoomsText] = useState("");

  useEffect(() => {
    setValue(runningCurrent);
  }, [runningCurrent]);
  useEffect(() => {
    setMaxAmpere(maxAmp);
  }, [maxAmp]);

  useEffect(() => {
    if (data) {
      setBatteryBankSize(data.battery_bank_size);
      setMaxAmp(data.max_amp);
      setMinInverterSize(data.min_inverter_size);
      setMinLandRequired(data.min_land_required);
      setPvSize(data.pv_size);
      setSpaceVolumeNeeded(data.space_volume_needed);
      setRunningCurrent(data.running_current);
      setDod(data.dod);
      setLinkID(data.results_link);
      setRooms(data.location_rooms);
      setPeople(data.location_people);
      setMohafazaName(data.region_breadcrumbs.mouhafaza_name);
      setCasaName(data.region_breadcrumbs.caza_name);
      setRegionName(data.region_breadcrumbs.region);
    }
  }, [data]);

  useEffect(() => {
    axios
      .get(
        `https://api.undpcalculator.whitebeard.net/api.php?action=results&id=${id}&lang=ar`
      )
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  const results = [
    {
      number: <p className="mb-0 card-number">{batteryBankSize}</p>,
      unit: "كيلوواط ساعة",
      image: <img src={battery} alt="" className="pt-3 result-card-image" />,
      title: "بنك بطارية ليثيوم",
      text:
        "A type of rechargeable battery which uses the reversible reduction of lithium ions to store energy",
    },
    {
      number: <p className="mb-0 card-number">{pvSize}</p>,
      unit: "كيلوواط الذروة ",
      image: <img src={system} alt="" className="pt-3 result-card-image" />,
      title: " كحد أدنى بالنسبة لحجم النظام الكهروضوئي",
      text:
        "الحد الأدنى من مساحة السطح أو الأرض المطلوبين: المساحة التي ستتم تغطيتها بكافة الألواح الشمسية بما في ذلك توجيهها لتجنب التظليل",
    },
    {
      number: <p className="mb-0 card-number">{minLandRequired}</p>,
      unit: "م2",
      image: <img src={roof} alt="" className="pt-3 result-card-image" />,
      title: "مساحة قدرها تقريباً للسقف أو الأرض المطلوبين",
      text:
        "الحد الأدنى من مساحة السطح أو الأرض المطلوبين: المساحة التي ستتم تغطيتها بكافة الألواح الشمسية بما في ذلك توجيهها لتجنب التظليل",
    },
    {
      number: <p className="mb-0 card-number">{spaceVolumeNeeded}</p>,
      unit: "م3",
      image: <img src={space} alt="" className="pt-3 result-card-image" />,
      title: " المساحة اللازمة للبطاريات بحجم تقريبي ",
      text:
        "الحجم التقريبي للمساحة اللازمة للبطاريات: يجب أن تكون المساحة اللازمة لتركيب البطاريات جيدة التهوئة وبعيدة عن الماء (المطر) والشمس",
    },
    {
      number: <p className="mb-0 card-number">{minInverterSize}</p>,
      unit: "كيلوواط",
      image: <img src={inverter} alt="" className="pt-3 result-card-image" />,
      title: "حجم العاكس  كحد أدنى",
      text:
        "الحد الأدنى لحجم العاكس: أقصى طاقة يمكن استهلاكها قبل انقطاع التيار الكهربائي",
    },
    {
      number: <p className="mb-0 card-number">{maxAmp}</p>,
      unit: "أمبير",
      image: <img src={amps} alt="" className="pt-3 result-card-image" />,
      title: "توفير أمبير كحد أقصى من العاكس",
      text:
        "الحد الأقصى من الأمبيرات التي يتم توفيرها من العاكس: الحد الأقصى من التيار الذي يمكن الحصول عليه قبل انقطاع التيار الكهربائي",
    },
  ];
  var min = 0;
  var max = maxAmpere;
  function calculate(e) {
    setValue(e.target.value);
  }
  useEffect(() => {
    setNewValue(((value - min) / (max - min)) * 100);
  }, [value]);

  const copy = async () => {
    await navigator.clipboard.writeText(
      `I just used this solar calculator from UNDP to get an energy management estimate for my home, try it here: https://undpcalculator.whitebeard.net/results/#/${linkID} `
    );
    // alert("Text copied");
  };

  useEffect(() => {
    if (regionName === "All") {
      setNoRegion(true);
    }
  }, [regionName]);

  useEffect(() => {
    if (data && mohafazaName === casaName) {
      setMohafazaCasa(true);
    }
  }, [casaName, mohafazaName]);

  function screenshot() {
    setHideButtons(true);
    const input = document.getElementById("results-page");
    setTimeout(() => {
      html2canvas(input).then((canvas) => {
        const imgWidth = 208;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let a = document.createElement("a");
        a.download = "undp-calculator-results.jpg";
        a.href = canvas.toDataURL("image/jpg");
        a.click("JPG", 0, 0, imgWidth, imgHeight);
        setHideButtons(false);
      });
    }, 1000);
  }

  useEffect(() => {
    if (people != 0 && rooms != 0) {
      setPeopleText(` و ${people} أشخاص`);
    } else if (people != 0 && rooms == 0) {
      setPeopleText(` ويأوي ${people} أشخاص`);
    }
  }, [people, rooms]);

  useEffect(() => {
    if (rooms != 0) {
      setRoomsText(` ويتكون من ${rooms} غرف`);
    }
  }, [rooms]);

  return (
    <div>
      <div className="results-page pb-3 mx-lg-auto direction" id="results-page">
        <h1
          className={`text-light pt-5 start-text mx-2 font-arabic-semibold ${mohafazaCasa ===
            true && "d-none"}`}
        >
          {noRegion === false
            ? `منزلك في ${regionName}, ${mohafazaName}, ${casaName}${roomsText}${peopleText}.`
            : `منزلك في ${mohafazaName}, ${casaName}${roomsText}${peopleText}.`}
        </h1>
        <h1
          className={`text-light pt-5 start-text mx-2 font-arabic-semibold ${mohafazaCasa ===
            false && "d-none"}`}
        >
          {mohafazaCasa === true
            ? `منزلك في ${casaName}${roomsText}${peopleText}.`
            : ""}
        </h1>
        <h3 className="pt-4 text-light font-arabic-regular">
          بناءً على مدخلاتك، سوف تحتاج إلى:
        </h3>
        <div className="d-flex row justify-content-evenly m-auto mx-4">
          {results.map(({ image, number, unit, title, text }, index) => {
            return (
              <ResultCardAr
                image={image}
                title={title}
                unit={unit}
                number={number}
                key={index}
                text={text}
              />
            );
          })}
        </div>

        {/*  */}
      </div>
    </div>
  );
}

export default ResultIdAr;
