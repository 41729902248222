import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import tip from "../assets/GIF/tip.gif";

function AppliancesFeedback() {
  const location = useLocation();
  const navigate = useNavigate();
  const [rooms, setRooms] = useState("");
  const [people, setPeople] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [manager, setManager] = useState("");
  const [energy_manager, setEnergy_manager] = useState("");
  const [battery, setBattery] = useState("");
  const [appliance, setAppliance] = useState([]);
  const [appliancesUpdates, setAppliancesUpdates] = useState([]);
  const [mohafazaName, setMohafazaName] = useState("");
  const [casaName, setCasaName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [scrolled, setScrolled] = useState(false);
  const [areaPicked, setAreaPicked] = useState("");
  const [indexNew, setIndexNew] = useState("");
  const [clicksAdd, setClicksAdd] = useState("");
  const [tips, setTips] = useState([]);

  useEffect(() => {
    if (location.state) {
      setAreaPicked(location.state.areaPicked);
      setRooms(location.state.rooms);
      setPeople(location.state.people);
      setAge(location.state.age);
      setGender(location.state.gender);
      setManager(location.state.manager);
      setEnergy_manager(location.state.energy_manager);
      setBattery(location.state.battery);
      setAppliance(location.state.appliance);
      setAppliancesUpdates(location.state.appliancesUpdates);
      setMohafazaName(location.state.mohafazaName);
      setCasaName(location.state.casaName);
      setRegionName(location.state.regionName);
      setIndexNew(location.state.indexNew);
      setTips(location.state.tips);
      if (location.state.clicksAdd) {
        setClicksAdd(location.state.clicksAdd);
      }
    }
  }, [location.state]);

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     setScrollPosition(window.pageYOffset);
  //   });
  // }, [window.pageYOffset]);

  // useEffect(() => {
  //   if (scrollPosition > 50) {
  //     setScrolled(true);
  //   }
  // }, [scrollPosition]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setScrolled(true);
    }, 2000);
  }, []);

  const submit = (e) => {
    e.preventDefault();
    if (indexNew !== 6) {
      navigate("/appliances", {
        state: {
          indexNew: indexNew + 1,
          rooms: rooms,
          people: people,
          age: age,
          gender: gender,
          manager: manager,
          energy_manager: energy_manager,
          appliancesUpdates: appliancesUpdates,
          appliance: appliance,
          mohafazaName: mohafazaName,
          casaName: casaName,
          regionName: regionName,
          areaPicked: areaPicked,
          clicksAdd: clicksAdd,
        },
      });
    } else {
      navigate("/batteries", {
        state: {
          rooms: rooms,
          people: people,
          age: age,
          gender: gender,
          manager: manager,
          energy_manager: energy_manager,
          appliancesUpdates: appliancesUpdates,
          appliance: appliance,
          mohafazaName: mohafazaName,
          casaName: casaName,
          regionName: regionName,
          areaPicked: areaPicked,
        },
      });
    }
  };

  return (
    <div>
      <div className="appliances-feedback-page px-3 mx-lg-auto">
        <div className="appliances-feedback-page-header">
          <div className="image-height">
            <img src={tip} alt="" className="mt-2 pt-2" width="250" />
          </div>
          <h1 className="location-text mt-4 mx-3">
            Before you continue, our enginneers would give you this advice
          </h1>
        </div>
        <div>
          {tips.map((tip, index) => {
            return (
              <div className="background-white start-text pb-0" key={index}>
                <p className="py-4 mx-4">{tip}</p>
              </div>
            );
          })}

          <p className="appliances-feedback-tips-text pb-4">
            All these tips will be available on the results page. Press Next to
            check them later.
          </p>
        </div>
        <div className="appliances-feedback-page-background position-sticky">
          <Link
            to="/calculating"
            state={{
              rooms: rooms,
              people: people,
              mohafazaName: mohafazaName,
              casaName: casaName,
              regionName: regionName,
              age: age,
              gender: gender,
              manager: manager,
              battery: battery,
              appliance: appliance,
              appliancesUpdates: appliancesUpdates,
              energy_manager: energy_manager,
              areaPicked: areaPicked,
            }}
          >
            <button
              className="appliance-feedback-btn mt-2 rounded-pill mb-3"
              type="button"
              disabled={!scrolled}
              onClick={submit}
            >
              Next
            </button>
          </Link>

          <div className="d-flex padding-bottom justify-content-center">
            <div
              className={`active-feedback box`}
              onClick={() => {
                navigate(-3);
              }}
            ></div>
            <div
              className={`active-feedback box`}
              onClick={() => {
                navigate(-2);
              }}
            ></div>
            <div
              className={`active-feedback box`}
              onClick={() => {
                navigate(-1);
              }}
            ></div>
            <div className={`inactive-feedback box`}></div>
            <div className={`inactive-feedback box`}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppliancesFeedback;
